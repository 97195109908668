$button-background-color: #04a64b;
$button-text-color: #ffffff;
$red-button-text-color: #ffffff;
$card-button-background-color: #04a64b;
$inputs-background-color: #E3E4E5;
$default-font-color: #000000;
$default-link-color: #075599;
$hover-link-color: #04a64b;
$warning-font-color: #E94E1B;
$default-font-type: 'Roboto',helvetica,arial,sans-serif;
$default-button-font-size: 18px;
$default-heading-font-size: 18px;
$default-font-size: 16px;
$default-smaller-font-size: 14px;
$navbar-color: #000000;
$navbar-hover: #04a64b;
$deny-button-color: #E94E1B ;
$system-green: #04a64b;
$login-page-center-bar: $button-text-color;

$table-line-color: #e3e6f0;

$asterisk-color: #E94E1B;

$checkbox-radiobox-background-color: #E3E4E5;
$checkbox-active-color: #E94E1B;
$radiobox-active-color: #04a64b;

$carousel-stroke-color: #04a64b;
$carousel-inactive-color: #04a64b;
$carousel-active-color: #fff;

$photoUpload-image-size: 201px;
$photoUpload-button-size: 201px;

$horizontal-spacing-between-columns: 20px;

$back-button-background-color: #E3E4E5;

$textarea-background-color: #E3E4E5;
$textarea-font-color: #000;