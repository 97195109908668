@use "./defaultSettings.scss" as ds;
.App {
  text-align: center;
}

.logo {
  height: 500px;
  width:500px;
  pointer-events: none;
}

.header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
 
  font-size: calc(10px + 2vmin);
  color: ds.$system-green;
  max-width:500px;
  margin:0 auto;
}

.field{
  width:90%;
  margin-top:0px;
  margin-bottom:0px;
}
.successIcon{
  font-size:5.5rem;
  margin-top:10px;
  margin-bottom:20px;
  display:flex;
  align-items: center;
}

.errorheader {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
 
  font-size: calc(10px + 2vmin);
  color: ds.$warning-font-color;
  max-width:500px;
  margin:0 auto;
}


.questionH1 {
  background-color: #fff;

  display: flex;
  flex-direction: column;
 
  font-size: calc(10px + 2vmin);
  color: ds.$system-green;
  max-width:500px;
  margin:0 auto;
  margin-top:10px;
}

.questionH1Top {
  background-color: #fff;

  display: flex;
  flex-direction: column;
 
  font-size: calc(10px + 2vmin);
  color: ds.$system-green;
  max-width:500px;
  margin:0 auto;
  margin-top:18px;
}



.successHeader{
  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: ds.$system-green;
  max-width:500px;
  margin:0 auto;
  margin-top:18px;
}

.errorHeader {
  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: ds.$warning-font-color;
  max-width:500px;
  margin:0 auto;
  margin-top:18px;
}

.textField {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
 
  font-size: calc(10px + 2vmin);
  color: ds.$system-green;
  max-width:500px;
  margin:0 auto;
}

.radioGroup {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
 
  font-size: calc(10px + 2vmin);
  color: ds.$system-green;
  max-width:500px;
  margin:0 auto;
}

.progressBar {
  color: ds.$system-green;
  max-width:500px;
  margin:0 auto;
}



.bottomDiv{
  text-align: center;
  margin: 10px 5px 10px 5px;
}

.bottomParagraph{
  text-align:center;
}

@media screen and (max-width: 660px){
  .progressBar {
    color: ds.$system-green;
    max-width:320px;
    margin:0 auto;
  }
 
  .bottomDiv{
    text-transform: none;
    margin: 10px 30px 10px 30px;
  }

}